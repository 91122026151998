$(function() {
    var fileSelect = $("#search-files").select2({
        query: function(options) {
            $.getJSON(Routing.generate("file_search", {subdomain: getCurrentSubdomain()}), {q: options.term}, function (data) {
                options.callback({results:data});
            });
        },
        formatResult: function (item) {
            return item.ssn + "<br /><small>" + item.title + "</small>";
        }
    });

    fileSelect.on("change", function (event) {
        window.location.href = Routing.generate("file_details", {id: event.val, subdomain: getCurrentSubdomain()});
    })
});
