/**
 * @param type    Type of message: error, success, information or leave empty
 * @param message
 */
export default function flashMessage(message, type) {
    if (typeof type == "undefined") {
        type = "";
    }

    var alert = $("<div />")
        .text(message)
        .addClass('alert')
        .append("<a class='close' data-dismiss='alert' href='#'>&times;</a>");
    ;
    if (type != "") { alert.addClass("alert-" + type); }

    $(".flash-message-container").append(alert);


    alert.alert();
}
