'use strict';

import _ from 'underscore'

var contactValidationApp = angular.module('contactValidationApp', ['ui.select2']);

contactValidationApp.controller('ContactValidationCtrl', ['$scope', '$element', 'formElementConfigResolver', function ($scope, $element, formElementConfigResolver) {
    $scope.contactFormConfig = {};
    $scope.initialized = false;

    $scope.recalculateFormConfig = function () {
        angular.forEach($scope.contact, function(value, key) {
            $scope.contactFormConfig[key] = formElementConfigResolver.resolve(key, $scope.contact.possibleRoles);
        });
    }

    $scope.copyAddress = function() {
        $scope.contact.correspondenceAddressAddressLine1 = $scope.contact.residenceAddressAddressLine1;
        $scope.contact.correspondenceAddressAddressLine2 = $scope.contact.residenceAddressAddressLine2;
        $scope.contact.correspondenceAddressZipCode= $scope.contact.residenceAddressZipCode;
        $scope.contact.correspondenceAddressCity = $scope.contact.residenceAddressCity;
        $scope.contact.correspondenceAddressCountry = $scope.contact.residenceAddressCountry;
    }

    $scope.submit = function() {
        $element.find('form').submit();
    }

    $scope.$watch('contact.possibleRoles', function (oldValue, newValue) {
        if (oldValue == newValue && $scope.initialized) { return; }
        $scope.recalculateFormConfig();
        $scope.initialized = true;
    });
}]);

contactValidationApp.factory('formElementConfigResolver', function () {
    var resolver = {
        config: {
            REQUIRED: 'required',
            STANDARD: 'standard',
            UNAVAILABLE: 'unavailable'
        },

        roles: {
            APPLICANT: 'applicant',
            CORRESPONDENCE: 'correspondence',
            DEBTOR: 'debtor',
            FINANCIAL_CORRESPONDENCE: 'financial_correspondence',
            FOREIGN_AGENT: 'foreign_agent',
            FORMALITIES_OFFICER: 'formalities_officer',
            INVENTOR: 'inventor',
            IP_PROFESSIONAL: 'ip_professional',
            LAWYER: 'lawyer',
            OLD_APPLICANT: 'old_applicant',
            OPPONENT: 'opponent',
            PTO: 'pto',
            THIRD_PARTY: 'third_party'
        },

        /**
         *
         * @param elementName
         * @param possibleRoles
         * @return string (one of this.config)
         */
        resolve: function(elementName, possibleRoles) {
            if (!(elementName in this.configs)) {
                throw new Error(`No configuration for ${elementName}`);
            }

            if (!possibleRoles) {
                possibleRoles = [];
            }

            var required = _.intersection(possibleRoles, this.configs[elementName]['required']);
            var unavailable = _.intersection(possibleRoles, this.configs[elementName]['unavailable']);

            return required.length > 0
                ? this.config.REQUIRED
                : (unavailable.length == possibleRoles.length && unavailable.length > 0
                        ? this.config.UNAVAILABLE
                        : this.config.STANDARD
                );
        }
    };

    var r = resolver.roles;

    var defaultUnavailableRoles = [r.CORRESPONDENCE, r.FINANCIAL_CORRESPONDENCE, r.INVENTOR, r.IP_PROFESSIONAL, r.FORMALITIES_OFFICER];
    var defaultSet = {required: [], unavailable: defaultUnavailableRoles};
    var emptySet = {required: [], unavailable: []};
    var emptySetNoPTO = { required: [], unavailable: [r.PTO] };

    resolver.configs = {
        possibleRoles: emptySet,
        concerns: emptySet,
        tradeName: {
            required: [r.FOREIGN_AGENT, r.LAWYER, r.THIRD_PARTY, r.PTO, r.OPPONENT],
            unavailable: defaultUnavailableRoles
        },
        officialName: {
            required: [r.APPLICANT, r.OLD_APPLICANT, r.DEBTOR, r.FOREIGN_AGENT, r.LAWYER, r.THIRD_PARTY, r.PTO, r.OPPONENT],
            unavailable: defaultUnavailableRoles
        },
        alias: emptySetNoPTO,
        faxNumber: emptySet,
        generalEmailAddress: defaultSet,
        generalPhoneNumber: defaultSet,
        website: emptySet,
        officialRegistrationNumber: {
            required: [r.DEBTOR],
            unavailable: defaultUnavailableRoles
        },
        vatNumber: defaultSet,
        branch: defaultSet,
        size: defaultSet,
        legalForm: {
            required: [r.APPLICANT, r.OLD_APPLICANT, r.DEBTOR, r.FOREIGN_AGENT, r.LAWYER, r.THIRD_PARTY],
            unavailable: defaultUnavailableRoles
        },
        anniversary: defaultSet,
        residenceAddressAddressLine1: { required: [r.APPLICANT, r.OLD_APPLICANT, r.DEBTOR], unavailable: [] },
        residenceAddressAddressLine2: emptySet,
        residenceAddressZipCode: { required: [r.APPLICANT, r.OLD_APPLICANT, r.DEBTOR], unavailable: []},
        residenceAddressCity: { required: [r.APPLICANT, r.OLD_APPLICANT, r.DEBTOR], unavailable: []},
        residenceAddressCountry: { required: [r.APPLICANT, r.OLD_APPLICANT, r.DEBTOR], unavailable: []},
        correspondenceAddressAddressLine1: { required: [r.PTO], unavailable: [] },
        correspondenceAddressAddressLine2: emptySet,
        correspondenceAddressZipCode: { required: [r.PTO], unavailable: [] },
        correspondenceAddressCity: { required: [r.INVENTOR, r.PTO], unavailable: [] },
        correspondenceAddressCountry: { required: [r.INVENTOR, r.PTO], unavailable: [] },

        lastName: {
            required: [r.CORRESPONDENCE, r.APPLICANT, r.OLD_APPLICANT, r.DEBTOR, r.FINANCIAL_CORRESPONDENCE, r.INVENTOR, r.FOREIGN_AGENT, r.LAWYER, r.THIRD_PARTY, r.IP_PROFESSIONAL, r.FORMALITIES_OFFICER],
            unavailable: [r.PTO]
        },
        fullFirstNames: {
            required: [r.APPLICANT, r.OLD_APPLICANT, r.DEBTOR, r.INVENTOR, r.IP_PROFESSIONAL],
            unavailable: [r.PTO]
        },
        givenName: {
            required: [r.IP_PROFESSIONAL, r.FORMALITIES_OFFICER],
            unavailable: [r.PTO]
        },
        directPhoneNumber: emptySetNoPTO,
        mobilePhoneNumber: emptySetNoPTO,
        directEmailAddress: {
            required: [r.CORRESPONDENCE, r.FINANCIAL_CORRESPONDENCE, r.IP_PROFESSIONAL],
            unavailable: [r.PTO]
        },
        correspondenceLanguage: {
            required: [r.CORRESPONDENCE, r.FINANCIAL_CORRESPONDENCE, r.FOREIGN_AGENT, r.LAWYER, r.THIRD_PARTY, r.IP_PROFESSIONAL, r.FORMALITIES_OFFICER],
            unavailable: [r.PTO]
        },
        function: emptySetNoPTO,
        title: emptySetNoPTO,
        dateOfBirth: emptySetNoPTO,
        hourlyRate: { required: [r.IP_PROFESSIONAL], unavailable: [r.PTO] },
        nationality: { required: [r.INVENTOR], unavailable: [r.PTO] },
        socialMediaInformation: emptySetNoPTO,
        serviceLevel: emptySetNoPTO,
        plainPassword: emptySet
    };

    return resolver;
});
