import flashMessage from '../../lib/bootstrap-flash-message'

/**
 * Append the current phase to the action URL on submit
 */
$(document).ready(function () {
    $(".modal .document-form").submit(function (event) {
        var action = $(this).attr('action');
        var currentPhase = $(".tab-pane.active").attr('id').split("-")[1];

        if (!action.match(new RegExp(currentPhase + "$"))) {
            event.preventDefault();
            $(this).attr('action', action + "&phase=" + currentPhase);
            $(this).submit();
        }
    });

    $("#add-phase").popover({
        content: "<p><select></select></p>"
    });
});

$(document).ready(function () {
    $("#add_publication_date").datepicker({format: "dd/mm/yyyy"});

    $(".editable-value").each(function () {
        $(this).editable({
            url: $(this).data('href'),
            params: {prefix: 'file-'},
            pk: "na",
            emptytext: '',
            inputclass: 'span4',
            toggle: $("<a class='editable-control' href='#'><i class='icon-pencil'></i></a>")
        });
    });

    $(".editable-filefamily-value").each(function () {
        $(this).editable({
            url: $(this).data('href'),
            params: {prefix: 'fileFamily-'},
            pk: "na",
            emptytext: '',
            inputclass: 'span4',
            toggle: $("<a class='editable-control' href='#'><i class='icon-pencil'></i></a>")
        });
    });

    $("#file-filingDate").on('beforeShow', function (e) {
        return confirm("WARNING: Are you sure you want to edit the filing date?");
    });

    $("#file-status").on("update", function (e) {
        window.location.reload();
    });
});


$(document).ready(function() {
    $(".file-nav-tabs").sortable({
        items: "> li.sortable",
        update: function (event, ui) {
            var $this = $(this)
            var phases = [];
            $("li.sortable", $this).each(function (i, item) {
                phases.push($("a", $(item)).attr('href').substr(5));
            });

            $.post(
                Routing.generate('file_sort_phases', {id: $this.data('fileId'), subdomain: getCurrentSubdomain()}),
                {phases: phases},
                function () {
                    flashMessage("Order of phases successfully updated!", 'success');
                }
            );
        }
    });
})
