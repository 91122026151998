import './styles/vioma.less'
import './styles/boxes.css'

import './lib/select2/select2.css'
import './lib/bootstrap-select2/bootstrap_select2.css'
import './lib/bootstrap-editable/css/bootstrap-editable.css'
import './lib/bootstrap-datepicker/css/datepicker.css'
import './lib/datatables_extensions/dt_bootstrap.css'
import './lib/bootstrap-wysihtml5/bootstrap-wysihtml5-0.0.2.css'

import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'

Sentry.init({
    dsn: 'https://c4634d0c8aa04ceaa3752fd28867bd9e@o101312.ingest.sentry.io/5870918',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
})

import 'jquery'

import 'angular'
import 'angular-cookies'

import 'underscore'

import './lib/hammer.min'
import './lib/jquery.hammer.min'

import './lib/jquery-ui-1.10.2.custom.min'

import './lib/bootstrap/js/bootstrap-tab.js'
import './lib/bootstrap/js/bootstrap-transition.js'
import './lib/bootstrap/js/bootstrap-collapse.js'
import './lib/bootstrap/js/bootstrap-dropdown.js'
import './lib/bootstrap/js/bootstrap-modal.js'
import './lib/bootstrap/js/bootstrap-tooltip.js'
import './lib/bootstrap/js/bootstrap-popover.js'
import './lib/bootstrap/js/bootstrap-alert.js'

import './lib/select2/select2.js'
import './lib/select2/auto-select2.js'

import './lib/datatables/js/jquery.dataTables'
import './lib/datatables_extensions/dt_bootstrap'
import './lib/datatables_extensions/jquery.dataTables.columnFilter'
import './lib/datatables_extensions/jquery.referencingTableRows'

import './lib/bootstrap-contextmenu'
import './lib/bootstrap-datepicker/js/bootstrap-datepicker'
import './lib/bootstrap-editable/js/bootstrap-editable'
import './lib/bootstrap-flash-message'
import './lib/bootstrap-persistent-tabs'

import './lib/mopabootstrap-collection'

import './lib/jquery.modalForms'

import './lib/wysihtml5-0.3.0_rc2.min'
import './lib/bootstrap-wysihtml5/bootstrap-wysihtml5-0.0.2'

import './lib/angular-strap.min.js'
import './lib/angular-local-storage.js'
import './lib/angular-select2.js'

import './components/crm/address-copy-helper'
import './components/crm/ip-team-form'
import './components/crm/concerns-table'
import './components/crm/contact-validation'
import './components/crm/contacts-table'
import './components/crm/crm-widget'

import './components/documents/document-list';
import './components/documents/converation-note';

import './components/files/advanced-file-search/advanced-file-search'
import './components/files/file-details-page'
import './components/files/file-families-list'
import './components/files/file-info-form'
import './components/files/files-widget'
import './components/files/persons-roles-teams-form'
import './components/files/workflow'

import './components/finance/cost-form'
import './components/finance/cost-list'
import './components/finance/invoice-details'

import './components/workflow/flowchart'
import './components/workflow/template-editor'
import './components/workflow/tasks-widget/tasks-widget'

import './utils/bootstrap-modal-form.js'
import './utils/conditional-fields'
import './utils/fix-touch'
import './utils/safe-delete'
import './utils/select-all'
import './utils/subdomain-hack'

const $ = require('jquery');
global.$ = global.jQuery = $;
