
$(document).ready(function () {
    $("#conversation_note_note").wysihtml5({"link": false, "image": false});

    var setLabels = function (type) {
        if (type == "mail_out" || type == "fax_out" || type == "online") {
            $("#delivered-on-label").text("Delivered on");
            $("#delivered-to-label").text("Delivered to");
        } else if (type == "mail_in" || type == "fax_in") {
            $("#delivered-on-label").text("Received on");
            $("#delivered-to-label").text("Received from");
        }
    };

    $("#correspondence_delivered_to, #conversation_note_delivered_to").select2({
        query: function (query) {
            const fileId = this.element.parents('form').data('fileId');

            if (fileId) {
                $.getJSON(
                    Routing.generate('file_participant_list', {
                        "id": fileId,
                        "_format": "json",
                        "subdomain": getCurrentSubdomain()
                    }),
                    {q: query.term},
                    function (response) { query.callback(response); }
                );
            } else {
                var data = {results: []};
                $.getJSON(
                    Routing.generate('crm_contact_index', {"_format": "json", subdomain: getCurrentSubdomain()}),
                    {q: query.term},
                    function (response) {
                        $.each(response, function (key, val) {
                            data.results.push({id: val.id, text: val.label});
                        });
                        query.callback(data);
                    }
                );
            }
        }
    });

    $("#correspondence_type").change(function () { setLabels($(this).val()); });
    setLabels($("#correspondence_type").val());
});
