$(document).ready(function() {
    $(".deadline-type").on("change", function () { elementState($(this)); });
    $(".deadline-type").each(function (i, element) { elementState($(element)); });

    function elementState(sourceElement) {
        var form = sourceElement.parents('form');
        var elements = $("div[id='workflow_template_step_ruleSuggestedDeadline_control_group']", form);
        if (sourceElement.val() == 'input') {
            elements.hide();
            $('input', elements).prop('required', false);
        } else {
            elements.show();
            $('input', elements).prop('required', true);
        }
    }

    const templateId = $("#template-name").data('templateId');
    if (templateId) {
        $("#template-name").editable({
            url: Routing.generate('property_update', {
                entity_class: 'ViomaWorkflowBundle:WorkflowTemplate',
                entity_id: templateId,
                subdomain: getCurrentSubdomain(),
            }),
            params: { prefix: 'template-'},
            pk: templateId,
            emptytext: '',
            inputclass: 'span4',
            toggle: $("<i class='icon-pencil' style='cursor:pointer;margin-left:5px'></i>")
        });
    }
})
