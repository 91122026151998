$(document).ready(function () {
    $("#ip_team_members_control_group").bind('add.mopa-collection-item', function (event, row) {
        initRow(row);
        $("tr", row).unwrap();
    });

    $("#ipteam-members-table tr.collection-item").each(function (i, row) {
        initRow(row);
    });

    function initRow(row) {
        $(".contact").data('source-url', Routing.generate("crm_contact_index", {"_format": 'json', subdomain: getCurrentSubdomain()}));
        $(".contact", row).select2({
            width: '275px',
            query: function (query) {
                var data={results: []};
                var url = this.element.data('source-url');
                var role = $(".role", this.element.parents('.collection-item')).val();

                $.getJSON(
                    url,
                    {
                        q: query.term,
                        possible_roles: [role]
                    },
                    function (response) {
                        $.each(response, function (key, val) {
                            data.results.push({id: val.id, text: val.label});
                        });
                        query.callback(data);
                    }
                );
            },
            initSelection: function (element, callback) {
                $.getJSON(Routing.generate("crm_contact_details", {id: element.val(), "_format": "json", subdomain: getCurrentSubdomain()}), function (data) {
                    callback({id: element.val(), text: data.label});
                });
            }
        });
    }
});
