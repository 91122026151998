$(document).ready(function() {
    var anchor = $("li a", $(".file-families-list").next('.pagination')).each((i, el) => {
        el.href = el.href.split('#')[0] + '#tab-file-families';
    });
});

$(document).ready(function () {
    $(".file-families-list").on("show.context-menu.data-api", ".typical-drawing", function (e) {
        $("#update-typical-drawing-link").data('family-id', $(this).parents('li').data('family-id'));
    });

    $("#update-typical-drawing-link").click(function () {
        var familyId = $(this).data('family-id');
        $("#typical-drawing-form").attr('action', Routing.generate('file_family_update_typical_drawing', {id: familyId, subdomain: getCurrentSubdomain()}));
    });
});
