'use strict';

import modalTemplate from './save-query.modal.html'

var app = angular.module("fileSearch", ['ui.select2', '$strap.directives', 'localStorage']);

app.run(['$templateCache', function($templateCache) {
    $templateCache.put('save-query.modal.html', modalTemplate);
}]);

app.controller('FileSearchCtrl', ['$scope', '$http', '$store', '$location', function ($scope, $http, $store, $location) {
    $store.bind($scope, 'lastQuery', null);
    var reset = $location.absUrl().match(/reset=1/);
    $scope.currentQuery = $scope.lastQuery && !reset ? angular.copy($scope.lastQuery) : {rules: [], extraParameters: []};

    if (reset) $scope.lastQuery = null;

    $scope.queryToSave = {};

    $scope.loading = false;

    $scope.parameters = window.searchConfiguration.parameters;
    $scope.operators = window.searchConfiguration.operators;

    $scope.addRule = function() { $scope.currentQuery.rules.push({logicalOperator: 'AND'}); }
    $scope.removeRule = function(index) { $scope.currentQuery.rules.splice(index, 1); }
    $scope.initRule = function(rule) {
        if (!rule.parameter) { return; }
        var operators = $scope.operators[$scope.parameters[rule.parameter].type];
        if (operators.length == 1) { rule.comparisonOperator = operators[0]; }
    }

    $scope.resetFilters = function() {
        $scope.lastQuery = null;
        $scope.currentQuery = {rules: [], extraParameters: []};
        $scope.addRule();
    }

    $scope.search = function() {
        $scope.lastQuery = angular.copy($scope.currentQuery);

        $scope.loading = true;
        $scope.errored = false;

        $http({
            method: 'POST',
            url: Routing.generate("file_advanced_search_execute", {subdomain: getCurrentSubdomain()}),
            data: {rules: $scope.lastQuery.rules, extraParameters: $scope.lastQuery.extraParameters}
        }).success(function(results) {
            if (typeof results != "object") {
                $scope.loading = false;
                $scope.searched = false;
                $scope.errored = true;
                $scope.lastQuery.results = [];
            } else {
                $scope.loading = false;
                $scope.searched = true;
                $scope.lastQuery.results = results;
            }
        })
        .error(function() {
            $scope.loading = false;
            $scope.searched = false;
            $scope.errored = true;
            $scope.lastQuery.results = [];
        });
    }

    $scope.saveQuery = function() {
        var routeOptions = {subdomain: getCurrentSubdomain()};
        var data = {
            name: $scope.queryToSave.name,
            query: JSON.stringify($scope.currentQuery)
        };

        if ($scope.queryToSave.id) {
            data.id = $scope.queryToSave.id;
        }

        $http({
            method: 'POST',
            url: Routing.generate('file_advanced_search_save', routeOptions),
            data: data
        }).success(function (results) { window.location.reload(); })
            .error(function (results) { window.location.reload(); });
    }

    $scope.removeSavedQuery = function () {
        if (confirm("Are you sure you want to delete this query?")) {
            window.location.href = Routing.generate('file_advanced_search_remove', {id: $scope.queryToSave.id, subdomain: getCurrentSubdomain()});
        }
    }

    if (window.savedQuery) {
        $scope.currentQuery = window.savedQuery;
        $scope.queryToSave.name = window.savedQueryName;
        $scope.queryToSave.id = window.savedQueryId;
        $scope.search();
    }


    if ($scope.currentQuery.rules.length == 0) $scope.addRule();
}]);

app.filter('humanize', function () {
    return function (input) {
        return input
            .replace(/([A-Z])/g, function (match) { return " " + match; })
            .toLowerCase()
            .replace(/^[a-z]/, function (match) { return match.toUpperCase(); })
        ;
    };
});

app.filter('parameter', function () {
    return function (input) {
        if (input == 'grantNumber') { return 'grant/registration number'; }
        if (input == 'dateOfGrant') { return 'date of grant/registration'; }
        return input;
    }
});
