$(document).ready(function () {
    $(".costs-table").on("change", "input[type='checkbox']", function () {
        setButtonsVisibility($(this).parents('table'));
    });

    $("#bill-cost-button").click(function () {
        $.post(
            Routing.generate('cost_bulk', {subdomain: getCurrentSubdomain()}),
            {'action': 'bill', 'costs': getCurrentCostIds(), 'parent': getParent()},
            function (data) {
                window.location.reload();
            }
        );
    });

    $("#remove-cost-button").click(function () {
        $.post(
            Routing.generate('cost_bulk', {subdomain: getCurrentSubdomain()}),
            {'action': 'remove', 'costs': getCurrentCostIds(), 'parent': getParent()},
            function (data) {
                window.location.reload();
            }
        )
    })

    $(".check-all-button").on("click", function () { $(".costs-table input").prop('checked', true); setButtonsVisibility(); });
    $(".check-none-button").on("click", function () { $(".costs-table input").prop('checked', false); setButtonsVisibility(); });
});

function getCurrentCostIds() {
    return $.map($("input[type='checkbox'][name='costs[]']:checked").toArray(), function (i) { return $(i).val(); })
}

function setButtonsVisibility($table) {
    var show = $("input[type='checkbox']:checked", $table).length > 0;
    if (show) { $(".bulk-action").removeClass('disabled'); }
    else { $(".bulk-action").addClass('disabled'); }
}

function getParent() {
    return window.location.href.includes('concern') ? 'concern' : 'file';
}