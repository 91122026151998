$(function() {
    var concernTypes = {
        client: 'Client', agent: 'Agent', prospect: 'Prospect', pto: 'PTO', lawyer: 'Lawyer',
        third_party: 'Third party', opponent: 'Opponent / Counter party', ip_firm: 'IP firm'
    };

    var concernsTable = $("#concerns-table")
        .dataTable({
            bAutoWidth: false,
            bProcessing: true,
            bServerSide: true,
            bLengthChange: false,
            iDisplayLength: 25,
            sAjaxSource: Routing.generate('crm_concern_table', {subdomain: getCurrentSubdomain()}),
            fnRowCallback: function (nRow, aData) {
                $(nRow).attr('data-href', Routing.generate('crm_concern_details', {id: aData[0], subdomain: getCurrentSubdomain()}));
            },
            fnDrawCallback: function () {
                concernsTable.referencingTableRows();
            }
        })
        .columnFilter({
            sPlaceHolder: "head:before",
            aoColumns: [
                {type: 'text'},
                {type: 'text'},
                {type: 'select', values: concernTypes},
                {type: 'text'}
            ]
        })
    ;
});
