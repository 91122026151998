$(document).ready(function() {
    $("#template-flowchart g.node").on("click", function (e) {
        var titleElement = e.currentTarget.getElementsByTagName("title")[0];
        if (!titleElement) {
            // todo: logging
            return;
        }

        var stepId = titleElement.textContent.split("_").reverse()[0];
        if (stepId == "start" || stepId == "finish") { return; }

        window.location.href = Routing.generate("workflow_template_step_details", {id: stepId, subdomain: getCurrentSubdomain()});
    });

    $("g.node").on("mouseover", function (e) {
        var backgroundElement = e.currentTarget.getElementsByTagName("polygon")[0];
        if (!backgroundElement) {
            // todo: logging
            return;
        }

        backgroundElement.setAttribute("fill", "#eee");
        $("body").css('cursor', 'pointer');
    });

    $("g.node").on("mouseout", function (e) {
        var backgroundElement = e.currentTarget.getElementsByTagName("polygon")[0];
        if (!backgroundElement) {
            // todo: logging
            return;
        }

        backgroundElement.setAttribute("fill", "#fff");
        $("body").css('cursor', 'auto');
    });
});
