$(document).ready(function() {
    $(".editable-invoice-value").each(function () {
        $(this).editable({
            url: $(this).data('href'),
            params: {"prefix": "invoice-"},
            pk: "na",
            emptytext: '',
            inputclass: 'span4',
            toggle: $("<a class='editable-control' href='#'><i class='icon-pencil'></i></a>")
        });
    });

    $("#invoice-debtorConcernContact").on('render', function(e) {
        window.location.reload();
    })

    $(".editable-file-value").each(function() {
        $(this).editable({
            url: $(this).data('href'),
            params: {"prefix": "file-"},
            pk: "na",
            emptytext: '',
            inputclass: 'span4',
            toggle: $("<a class='editable-control' href='#'><i class='icon-pencil'></i></a>")
        });
    });
})
