'use strict';

import tasksWidgetTemplate from './tasks-widget.html';
import configTemplate from './config.html';

var app = angular.module('tasks-widget', ['$strap.directives', 'localStorage', 'ui.select2']);

app.run(['$templateCache', function($templateCache) {
    $templateCache.put('task-widget-config.html', configTemplate);
}]);

app.factory('router', function () {
    var router = {};
    router.generate = function (route, routeOptions) {
        if (typeof routeOptions == "undefined") {
            var routeOptions = {};
        }
        if (typeof getCurrentSubdomain != 'undefined') {
            const subdomain = getCurrentSubdomain();
            if (subdomain !== 'vioma') {
                route += "_subdomain";
                routeOptions.subdomain = getCurrentSubdomain();
            }
        }

        return Routing.generate(route, routeOptions);
    }

    return router;
});

app.factory('taskConfig', ['$http', 'router', function ($http, router) {
    var taskConfigService = {};
    taskConfigService.get = function () {
        return $http.get(router.generate('tasks_widget_config'));
    }

    return taskConfigService;
}]);

app.factory('task', ['$http', 'router', function ($http, router) {
    var taskService = {};
    taskService.find = function (query) {
        return $http({
            url: router.generate('tasks_backend'),
            method: 'GET',
            params: query
        });
    }

    return taskService;
}]);

app.factory('assignee', ['$http', 'router', function ($http, router) {
    var assigneeService = {};
    assigneeService.findAll = function() {
        return $http.get(router.generate('tasks_assignees'));
    }

    return assigneeService;
}]);

app.factory('ipFirm', ['$http', 'router', function ($http, router) {
    var ipFirmService = {};
    ipFirmService.findAll = function() {
        return $http.get(router.generate('tasks_ipfirms'));
    }

    return ipFirmService;
}]);

app.directive('tasks', ['taskConfig', 'task', 'assignee', 'ipFirm', function(taskConfig, task, assignee, ipFirm) {
    return {
        restrict: 'E',
        template: tasksWidgetTemplate,
        scope: {},
        link: function (scope, element, attrs) {
            scope.widget = {
                title: attrs.title,
                ipFirmEnabled: (typeof attrs.ipFirmEnabled != "undefined"),
                configEnabled: (typeof attrs.configEnabled != "undefined"),
                loading: false,
                error: false
            };
            scope.query = {};
            scope.tasks = [];
            scope.assignees = [];
            scope.ipFirms = [];

            var errorHandler = function (result) {
                scope.widget.error = true;
                scope.loading = false;
            }

            scope.reloadTasks = function () {
                scope.tasks = [];
                scope.widget.loading = true;
                scope.widget.error = false;
                task.find(scope.query).then(
                    function (result) { scope.widget.loading = false; scope.tasks = result.data; },
                    function (result) { scope.widget.loading = false; scope.tasks = []; scope.widget.error = true; }
                );
            }

            var initialize = function () {
                scope.widget.loading = true;

                var loadConfig = function(result) {
                    scope.query.startDate = result.data.startDate ? new Date(result.data.startDate.replace(/"/g, '')) : null;
                    scope.query.endDate = result.data.endDate ? new Date(result.data.endDate.replace(/"/g, '')) : null;
                    scope.currentAssignee = result.data.assignee;
                    scope.currentIPFirm = result.data.ipFirm;
                };

                var loadAssignees = function (result) {
                    scope.assignees = result.data;
                    scope.query.assignee = scope.currentAssignee ? scope.currentAssignee : window.cuid;
                };

                var loadIpFirms = function (result) {
                    if (result == null) { return; }

                    scope.ipFirms = result.data;
                    scope.query.ipFirm = scope.currentIPFirm ? scope.currentIPFirm : null;
                }

                taskConfig.get()
                    .then(loadConfig, errorHandler)
                    .then(function () { return assignee.findAll(); })
                    .then(loadAssignees, errorHandler)
                    .then(function () { return scope.widget.ipFirmEnabled ? ipFirm.findAll() : null; })
                    .then(loadIpFirms, errorHandler)
                    .then(scope.reloadTasks)
                ;
            };

            initialize();
        }
    }
}]);
