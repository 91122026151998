$(document).ready(function () {
    $(".quick-view-button").on("click", function () {
        $(".quick-view", $(this).attr('data-target')).attr('src', $(this).data('href'));
    });

    $(".documents-table").on("show.context-menu.data-api", ".document-row", function (e) {
        $("#update_document_file_referer").val(window.location.href);
        $("#modal-upload-form").attr('action', Routing.generate('document_update_file', {id: $(this).data('document-id'), subdomain: getCurrentSubdomain()}));
    });

    $(".document-context-menu .delete-document").unbind("click");
    $(".document-context-menu .delete-document").on("click", function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (confirm("Are you sure you want to DELETE this document?")) {
            $.post(
                Routing.generate("document_delete", {id: $(this).data('id'), subdomain: getCurrentSubdomain()}),
                {"_method": "DELETE"}
            ).success(function () { window.location.reload(); });
        }
    });

    $(".documents-table.sortable[data-sortable-href] tbody").sortable({
        forceHelperSize: true,
        items: "> .document-row",
        update: function (event, ui) {
            var $this = $(this);

            $.post(
                $this.parents('table').data('sortableHref'),
                {documents: $(".document-row", $this).map((i, el) => $(el).data('documentId')).get()}
            )
        }
    });
});
