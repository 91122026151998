$(function() {
    var contactsTable = $("#contacts-table")
        .dataTable({
            bAutoWidth: false,
            bProcessing: true,
            bServerSide: true,
            bLengthChange: false,
            iDisplayLength: 25,
            sAjaxSource: Routing.generate('crm_contact_table', {subdomain: getCurrentSubdomain()}),
            fnRowCallback: function (nRow, aData) {
                $(nRow).attr('data-href', Routing.generate('crm_contact_details', {id: aData[0], subdomain: getCurrentSubdomain()}));
            },
            fnDrawCallback: function () {
                contactsTable.referencingTableRows();
            }
        })
        .columnFilter({
            sPlaceHolder: "head:before",
            aoColumns: [
                {type: 'text'},
                {type: 'select', values: {'person': 'Person', 'legal_entity': 'Legal entity'}},
                {type: 'text'},
                {type: 'text'},
                null
            ]
        })
    ;
});
