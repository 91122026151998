$(function(){
    // Function to activate the tab
    function activateTab() {
        if (!window.location.hash) {
            return;
        }

        var activeTab = $('[href="' + window.location.hash.replace('/', '') + '"]');
        activeTab && activeTab.tab('show');
    }

    // Trigger when the page loads
    activateTab();

    window.addEventListener('hashchange', function() {
        activateTab();
    })

    // Change hash when a tab changes
    $('a[data-toggle="tab"], a[data-toggle="pill"]').on('shown', function () {
        window.location.hash = '/' + $(this).attr('href').replace('#', '');
    });
});
