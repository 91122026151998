(function($) {
    var methods = {
        init: function (options) {
            $("tbody tr", this).each(function (i, row) {
                var href = $(row).attr('data-href');
                if (!href) { return; }
                $("td", $(row)).each(function (i, column) {
                    $(column).wrapInner("<a class='table-row-anchor' href='" + href + "' />");
                });
            });
        }
    };

    $.fn.referencingTableRows = function (method) {
        if (methods[method]) {
            return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
        }
        else if (typeof method === 'object' || !method) {
            return methods.init.apply(this, arguments);
        }
        else {
            $.error("Method '" + method + "' does not exist");
        }
    };
    
    $(document).ready(function () {
        $("table[data-toggle='referencing-rows']").referencingTableRows();
    });
})(jQuery);
