(function($) {
    var defaultOptions = {
        toggleElements : [],
        inverseToggle : false
    };
    
    var fetchValue = function (element) {
        if (element.prop('type') === 'checkbox') {
            return Boolean(element.prop('checked'));
        } else if (element.prop('tagName') === 'SELECT') {
            return Boolean(element.val());
        }
    };

    var methods = {
        init : function(options) {
            return this.each(function() {
                // Options set-up
                var $this = $(this);
                var currentOptions = $.extend({}, defaultOptions, options);
                $this.data('options', currentOptions);

                // Set visibility now, and bind to element changes
                $this.conditional("setVisibility");
                $this.change(function() {
                    $this.conditional("setVisibility")
                });

            });
        },
        setVisibility : function() {
            var elements = this.data('options').toggleElements;
            var value = fetchValue(this);
            value = this.data('options').inverseToggle ? !value : value;

            if (value) {
                elements.show();
            } else {
                elements.hide();
            }
        }
    };

    $.fn.conditional = function(method) {
        if (methods[method]) {
            return methods[method].apply(this, Array.prototype.slice.call(
                    arguments, 1));
        } else if (typeof method === 'object' || !method) {
            return methods.init.apply(this, arguments);
        } else {
            $.error('Method ' + method
                    + ' does not exist on jQuery.conditional');
        }
    };
})(jQuery);
