$(document).ready(function () {
    $("select.select2").select2({width: '220px'});
    $("form").on("change", "#workflow_template, #workflow_firstStep", function () {
        submitAndRefresh($("form"), $("#workflow-form"));
    });

    $("form").on("change", "#workflow_hardDeadline", function () {
        if ($("#workflow_firstStep").val()) {
            submitAndRefresh($("form"), $("#workflow-form"));
        }
    });

    function submitAndRefresh(form, container) {
        $("body").css('cursor', 'wait');
        $.post(
            window.location.href,
            form.serialize(),
            function (data) {
                $.get(window.location.href, function (data) {
                    container.replaceWith(data);
                    $("body").css('cursor', 'auto');
                    $("input[data-toggle='datepicker']").datepicker({"format": "yyyy-mm-dd"});
                    $("select.select2").select2({width: '220px'});
                });
            }
        );
    }


    // Complete task page
    $(document).ready(function () {
        $("form").on("change", "#assign_task_nextTask", function () {
            submit($("form"), $("#task-form"));
        });
    });

    function submit(form, container) {
        $("body").css('cursor', 'wait');
        $.post(
            window.location.href,
            form.serialize(),
            function (data) {
                container.replaceWith(data);
                $("body").css('cursor', 'auto');
                $("input[data-toggle='datepicker']").datepicker({"format": "yyyy-mm-dd"});
            }
        );
    }


});
