$(function() {
    var contactSelect = $("#search-contacts").select2({
        query: function(options) {
            $.getJSON(Routing.generate("crm_contact_index", {"_format": "json", subdomain: getCurrentSubdomain()}), {q: options.term}, function (data) {
                options.callback({results:data});
            });
        },
        formatResult: function (item) { return item.label; }
    });
    contactSelect.on("change", function (event) {
        window.location.href = Routing.generate("crm_contact_details", {id: event.val, subdomain: getCurrentSubdomain()});
    })

    var concernSelect = $("#search-concerns").select2();
    concernSelect.on("change", function (event) {
        window.location.href = Routing.generate("crm_concern_details", {id: event.val, subdomain: getCurrentSubdomain()});
    })
});
