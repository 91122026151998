$(document).ready(function () {
    var elements = [];
    elements.push("#file_info_newFileFamily_workTitle_control_group");
    elements.push("#file_info_newFileFamily_typicalDrawing_control_group");
    elements.push("#file_info_newFileFamily_concern_control_group");

	$("#file_info_fileFamily").conditional({
		inverseToggle: true,
		toggleElements: $(elements.join(", "))
	});			
});
	
	
	
	
/*	$("#file_info_file_family").select2({
		allowClear: true,
		placeholder: 'Select file family...',
//		createSearchChoice: function (term) {
//			console.log(term);
//		}, formatNoMatches: function (term) {
//			return "No matches found. <a href='javascript:void(0)' id='createFileFamily'>Create new family</a>";
//		}
	});*/
//});