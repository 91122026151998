$(document).ready(function () {
    $(".modal").on("submit", "form.form-remote", function (e) {
        $("body").css("cursor", "wait");
        e.preventDefault();
        $.post($(this).attr('action'), $(this).serialize(), function (data, textStatus, jqXHR) {
            $("body").css("cursor", "auto");
            if (jqXHR.getResponseHeader('X-Location')) {
                var currentHref = window.location.pathname;
                window.location.href = jqXHR.getResponseHeader('X-Location');
                if (jqXHR.getResponseHeader("X-Location").split("#")[0] == currentHref) {
                    location.reload();
                }
            } else {
                $(".modal-body").html(data);
            }
        });
    });
});
