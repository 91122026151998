$(document).ready(function () {
    // Initialize a 'participant-widget' when it is added
    $("#persons_roles_teams_participants_control_group").bind('add.mopa-collection-item', function (event, row) {
        initRow(row);
    });

    /**
     * Initialize a 'participant-widget'
     */
    function initRow(row) {
        // Styling
        row.addClass('span6 collection-item');

        // Make role select2 instance
        var roleSelect = $("select.file_role", row).select2({width: '275px'});
        roleSelect.on("change", function () {
            $(".file_contact", row).select2('val', '');
        });

        // Make contact select2 instance fetching data from /contacts.json
        $(".file_contact, .correspondence_contact").data('source-url', Routing.generate("crm_contact_index", {"_format": "json", subdomain: getCurrentSubdomain()}));
        $(".file_contact", row).select2({
            allowClear: true,
            width: '275px',
            query: function (query) {
                var data={results: []};
                var url = this.element.data('source-url');
                var role = $("select.file_role", this.element.parents('.collection-item')).val();

                $.getJSON(
                    url,
                    {
                        q: query.term,
                        possible_roles: [role]
                    },
                    function (response) {
                        $.each(response, function (key, val) {
                            data.results.push({id: val.id, text: val.label});
                        });
                        query.callback(data);
                    }
                );
            },
            initSelection: function (element, callback) {
                $.getJSON(Routing.generate("crm_contact_details", {id: element.val(), "_format": "json", subdomain: getCurrentSubdomain()}), function (data) {
                    callback({id: element.val(), text: data.label});
                });
            }
        });

        $(".correspondence_contact", row).select2({
            width: '275px',
            query: function (query) {
                var data={results: []};
                var url = this.element.data('source-url');
                $.getJSON(
                    url,
                    { q: query.term },
                    function (response) {
                        $.each(response, function (key, val) {
                            data.results.push({id: val.id, text: val.label});
                        });
                        query.callback(data);
                    }
                );
            },
            initSelection: function (element, callback) {
                $.getJSON(Routing.generate("crm_contact_details", {id: element.val(), "_format": "json", subdomain: getCurrentSubdomain()}), function (data) {
                    callback({id: element.val(), text: data.label});
                });
            }
        });

        // The 'use other contact for correspondence'-toggle
        $(".using_other_correspondence_contact", row).conditional({
            toggleElements: $(".correspondence_contact", row).parents('.control-group').first()
        });

        $(".using_other_correspondence_contact", row).on('change', function() {
            if (!$(this).prop('checked')) {
                $(".correspondence_contact", row).select2("val", null);
            }
        });
    }

    // If there are no participant widgets on load, add one
    var existingItems = $("#file-participants .collection-item");
    if (existingItems.length == 0) {
        $("#persons_roles_teams_participants_control_group").collection('add');
    } else {
        existingItems.each(function (i, item) { initRow($(item)); });
    }

    // Set the first field to correspondence and make it read-only
    var firstRoleField = $(".collection-item:first-child select.file_role").first();
    firstRoleField.select2('val', 'correspondence');
    firstRoleField.on('open', function (e) {
        e.preventDefault();
    });
    $(".select2-choice > div").first().remove();
    $(".collection-item:first-child>a.btn").remove();

});
