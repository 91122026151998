$(document).ready(function () {
    $(".copy-from-residence").click(function (e) {
        e.preventDefault();
        var type = $("#legal_entity_correspondenceAddress_addressLine1").length > $("#person_correspondenceAddress_addressLine1").length
                     ? '#legal_entity_'
                     : '#person_';

        var caPrefix = type + "correspondenceAddress_";
        var raPrefix = type + "residenceAddress_";
        var elements = ['addressLine1', 'addressLine2', 'zipCode', 'city', 'country'];

        for (i in elements) {
            if (elements[i] == 'country') { $(caPrefix + elements[i]).select2("val", $(raPrefix + elements[i]).val()); }
            else { $(caPrefix + elements[i]).val($(raPrefix + elements[i]).val()); }
        }        
    });
});