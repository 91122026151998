$(function() {
    $(document).ready(function() {
        function initForm() {
            const configEl = $("#cost-form-config");
            const hourlyRateMap = configEl.data('hourlyRates');
            const formUri = configEl.data('form-uri')

            $("#cost_sourceDocument").select2({width: '220px'});
            $("#cost_template").select2({width: '220px'});
            $("#cost_origin").select2({width: '220px'});
            $("#cost_template").on("change", function (event) {
                var $this = $(this);
                $.get(formUri.split("?")[0], {template: $this.val()}, function (data) {
                    $this.parents('.modal-body').html(data);
                    initForm();
                });
            });

            $("#cost_origin").on("change", function (e) {
                var $this = $(this);
                for (i in hourlyRateMap)
                {
                    if ($this.val() == hourlyRateMap[i][0]) {
                        $("#cost_pricePerUnit").val(hourlyRateMap[i][1]);
                    }
                }
            });
        }

        $("#add-cost-modal, #edit-cost-modal").on('loaded', function() {
            initForm();
        })
    })
    // const hourlyRates = $("#hourly-rate-map").data('hourlyRates');
    // console.log(hourlyRates);
    //
    // hourlyRateMap = JSON.parse(hourlyRates);
    // // var hourlyRateMap = {{ a | json_encode | raw }};
    //

});
